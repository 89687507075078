<template>
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="submit"/>
        <v-skeleton-loader v-if="api.isLoading" type="table" ref="skeleton">
        </v-skeleton-loader>
        <v-data-table
            v-if="data!=nulll"
            :items="data"
            :headers="dataHeader"
            :search="search"
            class="elevation-1 mt-5">
            <template
                v-slot:item.index="{item}">
                {{ data.indexOf(item)+1 }}
            </template>
            <template
                v-slot:top>
                <v-toolbar
                    width="auto"
                    height="auto"
                    class="py-3"
                    flat>
                    <v-row
                        no-gutters>
                        <v-col
                            class="mt-3 mx-3">
                            <v-row 
                                class="mb-3">
                                <v-col>
                                    <v-toolbar-title
                                        class="font-weight-bold text-h4 my-3">
                                        Referral Transaction
                                    </v-toolbar-title>
                                </v-col>
                                <v-col>
                                    <div
                                        class="d-flex justify-end">
                                        <v-btn
                                            :to="{name:'PageTransactionNew'}"
                                            color="primary">
                                            New
                                        </v-btn>

                                    </div>
                                </v-col>
                            </v-row>
                            <v-row class="mt-3">
                                <v-text-field
                                    dense 
                                    outlined 
                                    clearable 
                                    v-model="search"
                                    label="Search">

                                </v-text-field>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-toolbar>
            </template>
            <template 
                v-slot:item.created_at="{item}">
                <span>
                    {{ convertTimeZone(item.created_at) }}
                </span>
            </template>
            <template
                v-slot:item.referred_fname="{item}">
                <router-link :to="{name:'PageCompaniesDetail',params:{id:item.referred_current_company}}">
                    {{ item.referred_fname }}
                </router-link>
            </template>
            <template
                v-slot:item.referral_fname="{item}">
                <router-link
                    :to="{name:'PageCompaniesDetail',params:{id:item.referral_current_company}}">
                    {{ item.referral_fname }}
                </router-link>
            </template>
        </v-data-table>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
export default {
    components:{
    //
    },
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        data:null,
        search:null,
        dataHeader:[
            {
                text:'#',
                value:'index',
            },
            {
                text:'New Referral',
                value:'referred_fname',
            },
            {
                text:"Referred By",
                value:'referral_fname',
            },
            {
                text:"Points",
                value:'points',
            },
            {
                text:'Refer Date',
                value:'created_at'
            }
        ],
        isPending:false,
        api:
            {
                isLoading : false,
                isError:false,
                error:null,
                url:null,
                isSuccesful:false,
                success:null,
            }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getAllReferralTransaction"){
                this.data =resp.data;
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            let fetchReferralTransactionApi = this.fetchReferralTransaction();
            this.$api.fetch(fetchReferralTransactionApi);
        },
        fetchReferralTransaction(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method ="GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/referral/transaction";
            return tempApi;
        },
        validateInput(){
            this.isPending=true;
        },
        cancelSubmit(){
            this.isPending = false;
        },
        submit(){
            this.isPending = false;
            let reApplyCouponApi = this.reApplyCoupon();
            this.$api.fetch(reApplyCouponApi);
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
        },
    }
}
</script>